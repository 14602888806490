import React, { Fragment, useCallback, useState, Suspense } from "react";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { useParams } from "react-router-dom";
import { Card, CardContent, Button } from "@mobiscroll/react";
import { Modal } from "react-bootstrap";
import defaultPatientIcon from "src/assets/defaultPatient.svg";
import arrow from "src/assets/icons/black-arrow.svg";
import PastDiagnosisModal from "./PastDiagnosisModal";
import PatientDetailsPopup from "./PatientDetailsPopup";

//api
import { getPatientBasicDetails } from "../api/index";
import { useStore } from "./store/Store";

const AllergiesDialog = React.lazy(() => import("./dialogs/AllergiesPopup"));
const InsuranceDialog = React.lazy(() => import("./dialogs/InsurancePopup"));

const PatientSidebar = (props) => {
  const [isAllergiesDialog, setAllergiesDialog] = useState(false);
  const [isInsuranceDialog, setInsuranceDialog] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isHistoryDialog, setHistoryPopup] = useState(false);
  const { patientId } = useParams();

  const [isContactDialog, setContactDialog] = useState(false);
  const [isPersonalDialog, setPersonalDialog] = useState(false);
  const { globalState, dispatch } = useStore();

  React.useEffect(async () => {
    setLoader(true);
    const res = await getPatientBasicDetails(patientId);
    console.log(res);
    dispatch({
      type: "PATIENT_DETAILS",
      payload: res,
    });
    setPatientDetails(res);
    setLoader(false);
  }, []);
  const openAllergiesDialog = useCallback(() => {
    setAllergiesDialog(true);
  }, []);

  const closeAllergiesDialog = useCallback(() => {
    setAllergiesDialog(false);
  }, []);

  const openInsuranceDialog = useCallback(() => {
    setInsuranceDialog(true);
  }, []);

  const closeInsuranceDialog = useCallback(() => {
    setInsuranceDialog(false);
  }, []);
  const openHistoryDialog = useCallback(() => {
    setHistoryPopup(true);
  }, []);

  const closeHistoryDialog = useCallback(() => {
    setHistoryPopup(false);
  }, []);

  const openContactDialog = useCallback(() => {
    setContactDialog(true);
  }, []);

  const closeContactDialog = useCallback(() => {
    setContactDialog(false);
  }, []);
  const openPersonalDialog = useCallback(() => {
    setPersonalDialog(true);
  }, []);
  const closePersonalDialog = useCallback(() => {
    setPersonalDialog(false);
  }, []);

  return (
    <Fragment>
      <Loader show={loader} />
      <PatientDetailsPopup
        isDialog={isContactDialog}
        closeDialog={closeContactDialog}
        patientDetails={patientDetails}
        title="Contact details"
      />
      <PatientDetailsPopup
        isDialog={isPersonalDialog}
        closeDialog={closePersonalDialog}
        patientDetails={patientDetails}
        title="Personal details"
      />
      <Card className="docg-card docg-patient-sidebar">
        {/* <img src={PatientImage} className="docg-card-image" alt="doctor" /> */}
        <CardContent className="docg-card-content docg-patient-body px-0">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              {/* <div className="mbsc-col-5 mbsc-col-sm-5 mbsc-col-md-5 mbsc-col-lg-5 pl-0 mt-1"> */}
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={
                    patientDetails?.profilePicture
                      ? patientDetails?.profilePicture
                      : defaultPatientIcon
                  }
                  className="docg-card-image"
                  alt="doctor"
                />
              </div>
              {/* </div> */}

              <div className="d-flex flex-column ml-1">
                <span className="docg-patient-name">
                  <div className="docg-patient-name ml-1">
                    <span className="">
                      {patientDetails?.firstName || patientDetails?.lastName
                        ? `${
                            patientDetails?.title
                              ? patientDetails?.title + " "
                              : ""
                          }${patientDetails?.firstName} ${
                            patientDetails?.lastName
                          }`
                        : "-"}
                    </span>
                  </div>{" "}
                </span>
                <span>
                  {[
                    patientDetails?.age,
                    patientDetails?.gender,
                    patientDetails?.bloodGroup,
                  ]
                    .filter((key) => key != null)
                    .join(", ")}
                </span>
              </div>
            </div>

            <div
              className={`section d-flex gap-2 flex-column align-items-center justify-content-center`}
            >
              <span className="docg-patient-label">
                <Button
                  className="docg-patient-link"
                  onClick={() => openContactDialog()}
                >
                  Contact Details
                  <img src={arrow} alt="" />
                </Button>
              </span>
              <span className="docg-patient-label">
                <Button
                  className="docg-patient-link"
                  onClick={() => openPersonalDialog()}
                >
                  Personal Details
                  <img src={arrow} alt="" />
                </Button>
              </span>
            </div>
            <div className="section d-flex flex-column align-items-center justify-content-center">
              <span className="docg-patient-label">
                <Button
                  className="docg-patient-link"
                  onClick={() => openHistoryDialog()}
                >
                  Past Diagnosis
                  <img src={arrow} alt="" />
                </Button>
              </span>
            </div>

            <div className="section">
              <div className="d-flex flex-column">
                <span className="docg-patient-label">Social History</span>
                <span className="docg-patient-info">
                  {patientDetails?.socialHistory
                    ? patientDetails?.socialHistory?.join(", ")
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <PastDiagnosisModal
        isOpen={isHistoryDialog}
        onClose={closeHistoryDialog}
      />

      <Modal
        size="lg"
        show={isAllergiesDialog}
        onHide={closeAllergiesDialog}
        dialogClassName="followup-modal docg-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        {/* <Card className="docg-card"> */}
        {/* </Card> */}
        <Suspense fallback={<div>Loading...</div>}>
          <AllergiesDialog closePopup={closeAllergiesDialog} />
        </Suspense>
      </Modal>

      <Modal
        size="lg"
        show={isInsuranceDialog}
        onHide={closeInsuranceDialog}
        dialogClassName="followup-modal docg-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Suspense fallback={<div>Loading...</div>}>
          <InsuranceDialog closePopup={closeInsuranceDialog} />
        </Suspense>
      </Modal>
    </Fragment>
  );
};

export default PatientSidebar;
