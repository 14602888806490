import { gql } from "@apollo/client";

export const LIST_SPECIALIZATIONS = (search) => gql`
  query MyQuery {
    listSpecializations (search: "${search}", orderBy: "name=1", take: 2147483647) {
      data {
        name
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query MyQuery($data: GetClinicProfileRequest!) {
    getClinicProfile(data: $data) {
      message
      statusCode
      data {
        clinicTermsAndConditionsForApp {
          file
          fileName
          genericAccepted
          previousFiles
          updatedDate
        }
        clinicContract {
          file
          fileName
          genericAccepted
          previousFiles
          updatedDate
        }
        name
        registrationNumber
        signature
        specialities
        status
        about
        address
        clinicType
        contactDetails
        clinicLogoHeader
        contract
        contractAccepted
        description
        emergencyNumber
        gstin
        helplineNumber
        id
        logoLink
        mail
        currentSubscriptionId
        previousSubscriptions
      }
    }
  }
`;

export const GET_RAZORPAY_SUBSCRIPTION = gql`
  query MyQuery($data: String!) {
    getRazorpaySubscription(clinicId: $data) {
      data {
        subscriptionId
        planId
        chargeAt
        clinicId
        createdAt
        currentEnd
        currentStart
        customerNotify
        endAt
        entity
        expireBy
        remainingCount
        status
        startAt
        totalCount
      }
      message
      statusCode
    }
  }
`;

export const USER_DATA = gql`
  query MyQuery {
    getUserInfo {
      message
      statusCode
      data {
        clinicId
        profileId
        roles
        subscriptionStatus
        subscriptionStartTime
      }
    }
  }
`;

export const LIST_PATIENTS = gql`
  query MyQuery {
    listPatients(take: 100) {
      data {
        accountId
        id
        dateOfBirth
        firstName
        lastName
      }
    }
  }
`;

export const LIST_CLINIC_REQUEST = gql`
  query MyQuery {
    listClinicCreationRequests(take: 1000) {
      data {
        clinicAddress
        clinicName
        email
        firstName
        id
        lastName
        phoneNumber
        title
      }
    }
  }
`;
export const GET_DOCTOR_SCHEDULES = gql`
  query MyQuery($data: ListSchedulesRequest!) {
    listSchedules(data: $data) {
      data {
        duration
        end
        gap
        id
        relatedId
        doctorId
        recurrence {
          interval
          repeat
          until
          weekDays
        }
        start
      }
      statusCode
    }
  }
`;

export const GET_DOCTOR_ID = gql`
  query MyQuery {
    getMyProfile {
      data {
        id
      }
      message
      statusCode
    }
  }
`;

export const LIST_TIME_SLOTS = gql`
  query MyQuery($data: ListTimeslotsRequest!) {
    listTimeslots(data: $data) {
      message
      statusCode
      data {
        end
        groupId
        id
        scheduleId
        doctorId
        start
        status
        patient {
          email
          phoneNumber
          firstName
          lastName
          profilePicture
          id
        }
      }
    }
  }
`;

export const PROFILE_DETAILS = gql`
  query MyQuery {
    getMyProfile {
      message
      statusCode
      data {
        address
        award
        email
        firstName
        lastName
        middleName
        phoneNumber
        profilePicture
        specialization
        specializationsDescription
        title
        yearsOfExperience
      }
    }
  }
`;

export const DOCTOR_DETAILS = gql`
  query MyQuery {
    getMyProfile {
      data {
        doctorProfile {
          biography
          hospitalAffiliations
          medicalSchool
          residencies
        }
        degree
        certifications
        gender
        yearsOfExperience
        award
      }
    }
  }
`;

export const LIST_PATIENT_RECORDS = gql`
  query MyQuery {
    listDoctorPatients {
      data {
        firstName
        lastName
        profilePicture
        diagnosis
        lastVisit
        isActive
        followUp
        referredDoctor {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PATIENT_ALLERGIES = gql`
  query MyQuery($data: GetPatientRequest!) {
    getPatient(data: $data) {
      data {
        allergies {
          allergyType
          name
          reaction
          severity
        }
      }
      message
      statusCode
    }
  }
`;

export const LIST_DOCTOR_FEE = gql`
  query MyQuery($data: ID!) {
    listDoctorFee(doctorId: $data, orderBy: "createdAt=-1") {
      data {
        allowedFreeFollowUpVisits
        cancelationIn24Hours
        cancellationAllowed
        cashPaymentAccepted
        doctorId
        fee
        followUpExpiry
        followUpExpiryUnits
        id
        planName
        planType
        policy
        reschedulingAllowed
        reschedulingUnits
        status
      }
      message
    }
  }
`;

export const GET_UPLOAD_POLICY_LINK = gql`
  query MyQuery($data: UploadDoctorFeePolicyRequest!) {
    uploadDoctorFeePolicy(data: $data) {
      message
      statusCode
      data {
        url
        name
      }
    }
  }
`;

export const LIST_DOCTORS = gql`
  query MyQuery {
    listDoctors(take: 2147483647) {
      data {
        id
        firstName
        lastName
        profilePicture
        specialization
      }
    }
  }
`;

export const LIST_DOCTOR_MEMBERSHIP_PLANS = gql`
  query MyQuery {
    listDoctorMembershipPlans {
      data {
        description
        id
        name
        numberOfMembers
        numberOfVisits
        paymentFrequency
        price
        status
        uploadedFile
        validityTime
        validityTimeUnit
      }
      message
      statusCode
    }
  }
`;

export const GET_UPLOAD_MEMBERSHIP_FILE_LINK = gql`
  query MyQuery($data: UploadMPImageRequest!) {
    uploadMPImageUrl(data: $data) {
      message
      statusCode
      data {
        url
        name
      }
    }
  }
`;

export const LIST_SCHEDULE_CLINIC = gql`
  query MyQuery($data: ListSchedulesClinicRequest!) {
    listSchedulesClinic(data: $data) {
      data {
        start
        resource
        profilePicture
        end
      }
    }
  }
`;

export const LIST_CLINIC_PATIENT = (take, search) => gql`
  query MyQuery($data: [String!]!) {
    listClinicPatients(take: ${take},search:"${search}", doctorIds: $data, orderBy:"lastVisit=-1") {
      data {
        firstName
        id
        diagnosis
        followUp
        lastVisit
        isActive
        profilePicture
        lastName
        title
        createdAt
        referredDoctor {
          firstName
          lastName
        }
        doctor {
          id
          firstName
          lastName
          profilePicture
          title
        }
      }
      message
      totalCount
    }
  }
`;

export const GET_PATIENT = gql`
  query MyQuery($data: GetPatientRequest!) {
    getPatient(data: $data) {
      data {
        patientGrowthReport {
          heights {
            updatedAt
            height
            unit
          }
          weights {
            weight
            unit
            updatedAt
          }
        }
        profilePicture
        postalCode
        street
        state
        city
        phoneNumber
        email
        bloodGroup
        age
        socialHistory
        title
        weight
        height
        firstName
        lastName
        gender
        id
      }
      message
      statusCode
    }
  }
`;
export const LIST_INVOICES = (take, filters) => gql`
  query MyQuery {
    listInvoices(take: ${take}, filters: "${filters}", orderBy:"createdAt=-1") {
      data {
      patientId
      patientProfilePicture
      to
      createdAt
      amount
      status
      paymentMethod
      reason
      id
      uhid
    }
      message
      statusCode
      totalCount
    }
  }
`;

export const GET_DOCTOR_POLICIES = gql`
  query MyQuery($data: GetDoctorRequest!) {
    getDoctor(data: $data) {
      data {
        doctorProfile {
          policy {
            Online {
              status
            }
            Face2Face {
              cashPaymentAccepted
              status
            }
            Emergency {
              status
            }
          }
        }
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query MyQuery($data: GetInvoiceRequest!) {
    getInvoice(data: $data) {
      data {
        appointmentId
        createdAt
        doctorProfilePicture
        fromAddress
        reason
        amount
        to
        id
        uhid
        paymentMethod
        patientEmail
        patientPhoneNumber
        patientProfilePicture
        doctorEmail
        toAddress
        createdAt
        status
        items {
          amount
          cgst
          description
          quantity
          serialNumber
          sgst
          type
        }
        doctorName
        doctorPhoneNumber
        doctorId
        patientId
        signature
        fromAddress
      }
      message
      statusCode
    }
  }
`;

export const LIST_TODAY_APPOINTMENTS = (take, start, end, filters) => gql`
  query MyQuery($data:[String]) {
    listClinicAppointments(doctorIds:$data, take: ${take}, start:"${start}", end: "${end}", filters:"${filters}") {
      data {
        id
        doctorId
        paymentMode
        newPatient
        patient {
          firstName
          lastName
          profilePicture
          id
          phoneNumber
          email
        }
        doctor {
          firstName
          lastName
          profilePicture
          id
        }
        consultationType
        start
        end
        status
        patientStatus
      }
        totalCount

    }
  }
`;

export const LIST_CLINIC_PATIENTS_ALL = gql`
  query MyQuery($data: [String!]!) {
    listClinicPatients(doctorIds: $data, orderBy: "createdAt=-1") {
      data {
        firstName
        id
        profilePicture
        lastName
        title
      }
      message
      totalCount
    }
  }
`;

export const LIST_EMPLOYEES_CLINIC = (take, search) => gql`
  query MyQuery {
    listEmployeesClinic(take: ${take}, search: "${search}") {
      data {
        firstName
        lastName
        roles
        accountStatus
        profilePicture
        id
        email
        phoneNumber
        uhid
      }
      totalCount
      statusCode
    }
  }
`;
export const GET_NOTES = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        doctorId
        note {
          diagnosis {
            diagnosisName
            message
          }
          patientComplaints {
            text
            updatedAt
            updatedBy {
              firstName
              lastName
            }
          }
          procedure {
            message
            procedureName
          }
          planOfCare
          additionalAdvice
          bloodPressure
          bodyTemp
          height
          id
          immunizationStatus
          instruction
          nutritionalAdvice
          observation
          prognosis
          respRate
          pulseRate
          tempUnits
          visionTable {
            autoRefrectometer {
              leftEye {
                axis
                cyl
                spl
              }
              rightEye {
                axis
                cyl
                spl
              }
              label
            }
            eyePressure {
              iop {
                od
                os
              }
              method
              pachy {
                od
                os
              }
              time
            }
            vision {
              distance {
                withGlass {
                  od
                  os
                }
                unaided {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
              near {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
            }
          }
          visionTableExists
          weight
          weightUnits
        }
        prescription {
          eyePrescription {
            leftEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
            rightEye {
              near {
                axis
                cyl
                spl
                va
              }
              distance {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
          }
        }
      }
      message
      statusCode
    }
  }
`;

export const LIST_EYE_OBSERVATION_TEMPLATE = gql`
  query MyQuery {
    listEyeObservationTemplate(take: 2147483647) {
      data {
        column1
        column2
        id
      }
    }
  }
`;

export const LIST_VACCINATION_TEMPLATES = (search) => gql`
  query MyQuery {
    listVaccinationTemplates(take: 15, search: "${search}") {
      data {
        vaccinationName
        site
        route
        purpose
        dose
        age
        ageUnit
        id
      }
      statusCode
    }
  }
`;

export const LIST_PATIENT_VACCINATIONS = (patientId) => gql`
  query MyQuery {
    listPatientVaccinationDetails(patientId: "${patientId}", take:2147483647
) {
      data {
        vaccinationName
        status
        id
        completedDate
        dose
        dueDate
      }
      statusCode
    }
  }
`;
export const LIST_PATIENTS_CALENDAR = (search) => gql`
  query MyQuery($doctorIds: [String!]!) {
    listClinicPatients(doctorIds: $doctorIds, search:"${search}" orderBy: "lastVisit=-1", take:20) {
      data {
        id
        profilePicture
        firstName
        lastName
      }
    }
  }
`;
export const LIST_CLINIC_PATIENTS = (search) => gql`
  query MyQuery {
    listClinicPatientsAll(search:"${search}" orderBy: "lastVisit=-1", take: 20) {
      data {
        id
        profilePicture
        firstName
        lastName
      }
    }
  }
`;
export const TOTAL_APPOINTMENTS_COUNT = (filters) => gql`
  query MyQuery($data:[String]) {
    listClinicAppointments(doctorIds:$data, take: 2147483647, filters:"${filters}") {
      data {
        start
      }
        totalCount

    }
  }
`;
export const GET_APPOINTMENT_DETAILS = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        prescription {
          doctorId
          id
          patientId
          drugs {
            additionalAdvice
            composition
            dose
            drugName
            drugType
            duration
            gapBetweenDosage
            gapBetweenDosageType
            id
            instruction
            period
          }
          eyePrescription {
            leftEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
            rightEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
          }
        }
        note {
          additionalAdvice
          bloodPressure
          bodyTemp
          createdAt
          diagnosis {
            diagnosisName
            message
          }
          height
          id
          observation
          patientComplaints {
            text
            updatedAt
            updatedBy {
              firstName
              lastName
              profilePicture
              role
            }
          }
          pulseRate
          respRate
          tempUnits
          weight
          weightUnits
          immunizationStatus
          nutritionalAdvice
          instruction
          planOfCare
          procedure {
            message
            procedureName
          }
          prognosis
          visionTable {
            autoRefrectometer {
              leftEye {
                axis
                cyl
                spl
              }
              rightEye {
                axis
                cyl
                spl
              }
              label
            }
            eyePressure {
              iop {
                od
                os
              }
              method
              pachy {
                od
                os
              }
              time
            }
            vision {
              distance {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
              near {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
            }
          }
          visionTableExists
        }
        invoices
        tests {
          id
          instructions
          optionalInstructions
          testName
        }
        labTestReports
        consultationType
        delay
        doctorApprovement
        doctorId
        emergencyAppointment
        end
        id
        patientCheckedIn
        patientId
        payment {
          orderNumber
          verified
        }
        paymentMode
        price
        reasonForCancellation
        start
        status
        timeslotId
        survey {
          questions {
            id
            mediaDescription
            question
            textDescription
          }
        }
        eyePrescriptionExists
      }
      message
      statusCode
    }
  }
`;
export const LIST_PATIENT_HISTORY = (patientId) => gql`
  query MyQuery {
    listPatientHistory(patientId: "${patientId}", take:2147483647 , orderBy:"start=-1") {
     data {
      appointmentDetails {
        paymentMode
        id
        prescription {
          drugs {
            id
            drugName
          }
        }
        note{
          diagnosis{
            diagnosisName
            message
          }
        }
        tests {
          testName
        }
        start
      }
      doctorName
    }
    statusCode
  }
}
`;
export const PRESCRIPTION_DOWNLOAD_PDF = (
  appointmentId,
  doctorId,
  patientId,
  clinicId
) => gql`
query PrescriptionDownloadPDF {
  getAppointment(data: {id: "${appointmentId}"}) {
    data {
      prescription {
        doctorId
        id
        patientId
        drugs {
          additionalAdvice
          composition
          dose
          drugName
          doseFrequency
          drugType
          duration
          gapBetweenDosage
          gapBetweenDosageType
          id
          instruction
          period
        }
        eyePrescription {
          leftEye {
            distance {
              axis
              cyl
              spl
              va
            }
            near {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
          rightEye {
            distance {
              axis
              cyl
              spl
              va
            }
            near {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
        }
      }
      note {
        additionalAdvice
        bloodPressure
        bodyTemp
        createdAt
        diagnosis {
          diagnosisName
          message
        }
        height
        id
        observation
        patientComplaints {
          text
          updatedAt
        }
        pulseRate
        respRate
        tempUnits
        weight
        weightUnits
        immunizationStatus
        nutritionalAdvice
        instruction
        planOfCare
        procedure {
          message
          procedureName
        }
        prognosis
        visionTable {
          eyePressure {
            iop {
              od
              os
            }
            method
            pachy {
              od
              os
            }
            time
          }
          vision {
            distance {
              unaided {
                od
                os
              }
              withGlass {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
            near {
              unaided {
                od
                os
              }
              withGlass {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
          }
        }
        visionTableExists
      }
      invoices
      tests {
        id
        instructions
        optionalInstructions
        testName
      }
      consultationType
      delay
      doctorApprovement
      doctorId
      emergencyAppointment
      end
      id
      patientCheckedIn
      patientId
      payment {
        orderNumber
        verified
      }
      paymentMode
      price
      reasonForCancellation
      start
      status
      timeslotId
      survey {
        questions {
          id
          mediaDescription
          question
          textDescription
        }
      }
      eyePrescriptionExists
    }
  }
  getDoctor(data: {id: "${doctorId}"}) {
    data {
      doctorProfile {
        paymentPreference
        biography
        policy {
          Online {
            fee
            policy
            reschedulingAllowed
            cashPaymentAccepted
            cancelationIn24Hours
            cancellationAllowed
            planName
            planType
            reschedulingUnits
            followUpExpiryUnits
            followUpExpiry
            allowedFreeFollowUpVisits
          }
          Face2Face {
            fee
            policy
            reschedulingAllowed
            cashPaymentAccepted
            cancelationIn24Hours
            cancellationAllowed
            planName
            planType
            reschedulingUnits
            followUpExpiryUnits
            followUpExpiry
            allowedFreeFollowUpVisits
          }
        }
        reviews {
          comment
          createdAt
          rating
          id
        }
        averageRating
      }
      title
      address
      degree
      firstName
      lastName
      middleName
      specialization
      associatedClinics
      yearsOfExperience
      training
      profilePicture
      signature
      phoneNumber
      email
      medicalRegistrationNumber
    }
    statusCode
  }
  getPatient(data: {id: "${patientId}"}) {
    data {
      dateOfBirth
      email
      firstName
      gender
      phoneNumber
      street
      state
      lastName
      postalCode
      city
    }
  }
  getClinicProfile(data: {id: "${clinicId}"}) {
    data {
      about
      address
      contactDetails
      district
      logoLink
      mail
      name
      pinCode
      registrationNumber
    }
  }
}`;
export const LIST_ALL_NOTIFICATIONS = gql`
  query MyQuery {
    listAllNotifications(userType: Admin, orderBy: "createdAt=-1", take: 100) {
      data {
        createdAt
        acknowledged
        id
        metadata {
          ... on TermsUpdatedNotification {
            notificationType
            fileType
            updatedFile
          }
          ... on SubscriptionActiveNotification {
            notificationType
            subscriptionId
            clinicId
            planId
            status
          }
          ... on SubscriptionPausedNotification {
            notificationType
            subscriptionId
            clinicId
            planId
            status
          }
          ... on SubscriptionResumedNotification {
            notificationType
            subscriptionId
            clinicId
            planId
            status
          }
          ... on SubscriptionExpiringNotification {
            notificationType
            subscriptionId
            clinicId
            planId
            status
          }
          ... on SubscriptionCancelledNotification {
            notificationType
            subscriptionId
            clinicId
            status
            planId
          }
          ... on PatientLimitNotification {
            notificationType
            clinicId
            allowedPatients
            currentPatients
            planId
            subscriptionId
          }
          ... on SubscriptionPaymentNotification {
            notificationType
            subscriptionId
            chargeAt
            clinicId
            planId
            status
          }
          ... on SubscriptionUpdatedNotification {
            notificationType
            subscriptionId
            clinicId
            expireBy
            planId
            status
          }
          ... on SubscriptionFreeTrailEndNotification {
            notificationType
            subscriptionId
            status
            planId
            chargeAt
            clinicId
          }
          ... on PlanUpdatedNotification {
            notificationType
            subscriptionId
            clinicId
            expireBy
            planId
            status
          }
        }
      }
      statusCode
      message
    }
  }
`;

export const FETCH_SUBSCRIPTION_PLAN_BY_ID = gql`
  query MyQuery($data: String!) {
    fetchSubscriptionPlanById(planId: $data) {
      data {
        activePlan
        applicableTaxVerbiage
        appointmentsAllowed
        companyPercentage
        createdAt
        doctorsAllowed
        entity
        interval
        item {
          active
          amount
          createdAt
          currency
          description
          itemId
          name
          type
          unitAmount
          updatedAt
        }
        maxPatientsAllowed
        minPatientsAllowed
        period
        planId
        razorpayPercentage
        subscriptionType
        unit
        verbiageForTransactFee
      }
      message
      statusCode
    }
  }
`;

export const FETCH_SUBSCRIPTION_BY_ID = gql`
  query MyQuery2($data: String!) {
    fetchSubscriptionById(subscriptionId: $data) {
      data {
        chargeAt
        authAttempts
        clinicId
        createdAt
        currentEnd
        currentStart
        customerNotify
        endAt
        entity
        endedAt
        expireBy
        hasScheduledChanges
        notes {
          notesKey1
          notesKey2
        }
        offerId
        paidCount
        planId
        quantity
        shortUrl
        remainingCount
        signatureVerified
        startAt
        status
        subscriptionId
        totalCount
      }
      message
      statusCode
    }
  }
`;

export const CHECK_CLINIC_STATUS = gql`
  query MyQuery($email: AWSEmail!) {
    checkClinicExist(email: $email) {
      message
      statusCode
    }
  }
`;
export const GET_FILE_FOR_DOCG = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getFileForDocg(fileType: $fileType) {
      data {
        url
        name
        createdAt
      }
      message
      statusCode
    }
  }
`;
export const GET_FILE_FOR_CLINIC = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getGenericTermsFileForClinic(fileType: $fileType) {
      data {
        fileType
        createdAt
        name
        previousFileNames
        termsFor
        updatedAt
        url
      }
      message
      statusCode
    }
  }
`;
export const RAZORPAY_PLANS = gql`
  query MyQuery {
    listAllRazorpayPlans(isActive: true) {
      data {
        activePlan
        appointmentsAllowed
        createdAt
        doctorsAllowed
        entity
        interval
        item {
          active
          amount
          createdAt
          currency
          description
          itemId
          name
          type
          unitAmount
          updatedAt
        }
        subscriptionType
        period
        planId
        applicableTaxVerbiage
        companyPercentage
        maxPatientsAllowed
        minPatientsAllowed
        unit
        verbiageForTransactFee
      }
      message
      statusCode
    }
  }
`;

export const UPLOAD_CANCELLED_CHECK = gql`
  query MyQuery($requestId: String!, $ext: String!) {
    uploadCancelledCheque(requestId: $requestId, ext: $ext) {
      message
      statusCode
      data {
        url
      }
    }
  }
`;
export const UPLOAD_CLINIC_CONTRACT = gql`
  query MyQuery($requestId: String!) {
    uploadClinicContract(requestId: $requestId) {
      data {
        name
        url
      }
      message
      statusCode
    }
  }
`;

//invoice pdf
export const GET_INVOICE_DATA = (id) => gql`
query MyQuery {
  getInvoice(data: {id: "${id}"}) {
    data {
      patientEmail
      amount
      appointmentId
      createdAt
      doctorEmail
      doctorId
      doctorName
      doctorPhoneNumber
      doctorProfilePicture
      fromAddress
      id
      uhid
      items {
        amount
        cgst
        description
        quantity
        serialNumber
        sgst
        type
      }
      patientProfilePicture
      paymentMethod
      reason
      signature
      status
      to
      toAddress
      patientId
    }
    message
    statusCode
  }
}
`;

export const INVOICE_PDF_DOWNLOAD_DATA = (
  appointmentId,
  doctorId,
  patientId,
  clinicId
) => gql`
query PrescriptionDownloadPDF {
  getAppointment(data: {id: "${appointmentId}"}) {
    data {
      consultationType
      start
    }
  }
  getDoctor(data: {id: "${doctorId}"}) {
    data {
      firstName
      lastName
      signature
      phoneNumber
      email
      medicalRegistrationNumber
    }
    statusCode
  }
  getPatient(data: {id: "${patientId}"}) {
    data {
      dateOfBirth
      email
      firstName
      gender
      phoneNumber
      street
      state
      lastName
      postalCode
      city
    }
  }
  getClinicProfile(data: {id: "${clinicId}"}) {
    data {
      about
      address
      contactDetails
      district
      logoLink
      mail
      name
      pinCode
      registrationNumber
    }
  }
}`;

export const GET_CLINIC_DELETION_REQUEST = gql`
  query MyQuery($clinicId: String = "") {
    getClinicDeletionRequest(clinicId: $clinicId) {
      data {
        clinicId
        clinicName
        createdAt
        dataDownloadUrl
        dataStatus
        deletedDate
        deletionStatus
        downloadedDate
        id
        mail
      }
      statusCode
      message
    }
  }
`;
